// Create italic i for all product names.
const fanucis = document.querySelectorAll('h1, h2, h3, h4')

for (const fanuci of fanucis) {
    // Robot Names
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /0i/g,
        '0<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /1i/g,
        '1<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /2i/g,
        '2<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /3i/g,
        '3<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /4i/g,
        '4<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /5i/g,
        '5<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /6i/g,
        '6<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /7i/g,
        '7<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /8i/g,
        '8<em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /9i/g,
        '9<em class="italic-i">i</em>'
    )
    // CNC Names
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /iHMI/g,
        '<em class="italic-i">i</em>HMI'
    )
    // ROBODRILL Names
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /iB5/g,
        '<em class="italic-i">i</em>B5'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /iB(5)/g,
        '<em class="italic-i">i</em>B(5)'
    )
    // Software Names
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /MT-LINK i/g,
        'MT-LINK <em class="italic-i">i</em>HMI'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /Manual Guide i/g,
        'Manual Guide <em class="italic-i">i</em>'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /iRVision/g,
        '<em class="italic-i">i</em>RVision'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /iRPickTool/g,
        '<em class="italic-i">i</em>RPickTool'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /iRCalibration/g,
        '<em class="italic-i">i</em>RCalibration'
    )
    fanuci.innerHTML = fanuci.innerHTML.replace(
        /<em>i<\/em>/g,
        '<em class="italic-i">i</em>'
    )
}
